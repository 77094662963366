import React from 'react';
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Bell as BellIcon } from 'react-feather';
import './Notifications.css';

function Notifications() {
  return (
    <IconButton color="secondary" size="small" className="beamerTrigger">
      <SvgIcon>
        <BellIcon />
      </SvgIcon>
    </IconButton>
  );
}

export default Notifications;
