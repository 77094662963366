// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0'
export const API_BASE_URL = '/api'
export const ENABLE_REDUX_LOGGER = false

let backendHost
let razorpayKey, amplitudeApi
const hostname = window && window.location && window.location.hostname

if (hostname === 'merchants.printrove.com') {
    backendHost = 'https://api.printrove.com'
    razorpayKey = 'rzp_live_4VNYd1qaZkbxzW'
    amplitudeApi = 'ddc2fdbdf5cf9fd6c21f89521fbf6130'
} else if (/staging/.test(hostname) || /amplify/.test(hostname)) {
    backendHost = 'https://stagingapi.printrove.com'
    razorpayKey = 'rzp_test_FkrP9DUcOI8Cgf'
    amplitudeApi = '9e770135b0a5eb65a9938f7568e976c7'
} else if (/live/.test(hostname)) {
    backendHost = 'https://stagingapi.printrove.com'
    razorpayKey = 'rzp_test_FkrP9DUcOI8Cgf'
    amplitudeApi = '9e770135b0a5eb65a9938f7568e976c7'
} else {
    backendHost = 'http://ptapi.test'
    razorpayKey = 'rzp_test_f4gUVTDHZbunzS'
    amplitudeApi = 'e13f0fdcbb533b26a0043af1e2846ada'
}

export const API_ROOT = `${backendHost}/api`
export const RAZORPAY_KEY = razorpayKey
export const AMPLITUDE_API_KEY = amplitudeApi

export default {}
