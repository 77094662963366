import React, { lazy, Suspense } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthRoute from 'src/components/AuthRoute'
import GuestRoute from 'src/components/GuestRoute'
import VerifyRoute from './components/VerifyRoute'
import { useSelector } from 'react-redux'

function Routes() {
    const { user } = useSelector(state => state.account)
    let permissionsArray
    if (user && user.permissions) {
        permissionsArray = user.permissions.map(permission => permission.accessTo)
    }

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                <Redirect exact from="/" to="/app" />
                <Route exact path="/404" component={lazy(() => import('src/views/pages/Error404View'))} />
                <GuestRoute exact path="/login" component={lazy(() => import('src/views/auth/LoginAndRegister'))} />
                <GuestRoute exact path="/register" component={lazy(() => import('src/views/auth/LoginAndRegister'))} />
                <GuestRoute exact path="/forgot-password" component={lazy(() => import('src/views/auth/ForgotPassword'))} />
                <GuestRoute exact path="/reset-password" component={lazy(() => import('src/views/auth/ResetPassword'))} />
                <VerifyRoute exact path="/verify/email" component={lazy(() => import('src/views/auth/VerifyEmail'))} />
                <Route exact path="/onboarding" component={lazy(() => import('src/views/auth/Onboarding'))} />
                <Route exact path="/credits/add" component={lazy(() => import('src/views/pages/AddCredits'))} />
                <Route exact path="/login/shopify/:url/:token" component={lazy(() => import('src/views/auth/LoginAndRegister'))} />
                <AuthRoute
                    path="/app"
                    render={props => (
                        <DashboardLayout {...props}>
                            <Suspense fallback={<LoadingScreen />}>
                                <Switch>
                                    <Redirect exact from="/app" to="/app/dashboard" />
                                    <Route exact path="/app/dashboard" component={lazy(() => import('src/views/panel/Dashboard'))} />
                                    <Route exact path="/app/designs" component={lazy(() => import('src/views/panel/DesignLibrary'))} />
                                    <Route exact path="/app/mockups" component={lazy(() => import('src/views/panel/MockupGenerator'))} />

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('billing'))) && (
                                        <Route exact path="/app/billing" component={lazy(() => import('src/views/panel/Billing'))} />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('settings'))) && (
                                        <Route exact path="/app/settings" component={lazy(() => import('src/views/panel/Settings'))} />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('new_manual_order'))) && (
                                        <Route
                                            exact
                                            path="/app/order/new"
                                            component={lazy(() => import('src/views/panel/CreateOrUpdateOrder/CreateOrder'))}
                                        />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('manage_orders'))) && (
                                        <Route exact path="/app/orders" component={lazy(() => import('src/views/panel/ManageOrders'))} />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('edit_order'))) && (
                                        <Route
                                            exact
                                            path="/app/order/edit/:id"
                                            component={lazy(() => import('src/views/panel/CreateOrUpdateOrder/UpdateOrder'))}
                                        />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('returns'))) && (
                                        <Route
                                            exact
                                            path="/app/order/reship/:id"
                                            component={lazy(() => import('src/views/panel/CreateOrUpdateOrder/ReshipOrder'))}
                                        />
                                    )}

                                    <Route
                                        exact
                                        path="/app/order/removal"
                                        component={lazy(() => import('src/views/panel/CreateOrUpdateOrder/RemovalOrder'))}
                                    />
                                    <Route
                                        exact
                                        path="/app/order/view/:id"
                                        component={lazy(() => import('src/views/panel/ManageOrders/PlacedOrders/ViewOrder'))}
                                    />

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('reverse'))) && (
                                        <Route
                                            exact
                                            path="/app/reverse_order/view/:id"
                                            component={lazy(() => import('src/views/panel/ManageOrders/ReverseOrders/ViewOrder'))}
                                        />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('store'))) && (
                                        <Route exact path="/app/store" component={lazy(() => import('src/views/panel/Store'))} />
                                    )}

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('product_library'))) && (
                                        <Route exact path="/app/products" component={lazy(() => import('src/views/panel/ProductLibrary'))} />
                                    )}

                                    <Route exact path="/app/stock" component={lazy(() => import('src/views/panel/ListProducts'))} />

                                    {(user.isPrimary || (permissionsArray && permissionsArray.includes('product_library'))) && (
                                        <Route
                                            exact
                                            path="/app/product/:id"
                                            component={lazy(() => import('src/views/panel/ProductLibrary/ViewProduct'))}
                                        />
                                    )}

                                    <Redirect exact from="app/reports" to="/app/reports/dashboard" />
                                    <Redirect to="/404" />
                                </Switch>
                            </Suspense>
                        </DashboardLayout>
                    )}
                />
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    )
}

export default Routes
