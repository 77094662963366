import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, colors } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  buttonProgress: {
    color: colors.green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  greenButton: {
    backgroundColor: colors.green[500]
  },
  redButton: {
    backgroundColor: colors.red[500]
  }
}));

const SubmitButton = props => {
  const {
    disabled,
    loading,
    fullWidth,
    onClick,
    type,
    color,
    children,
    greenButton,
    redButton,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Button
      {...rest}
      className={clsx(
        fullWidth && classes.submitButton,
        greenButton && classes.greenButton,
        redButton && classes.redButton,
        className
      )}
      color={color}
      disabled={disabled}
      size="medium"
      type={type}
      variant={color && (color === 'secondary' ? 'outlined' : 'contained')}
      onClick={onClick}
    >
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {children}
    </Button>
  );
};

SubmitButton.propTypes = {
  // onClick: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
  // color: PropTypes.string.isRequired
};

export default SubmitButton;
