import * as axiosApi from 'axios';
import { API_ROOT } from 'src/config';
import humps from 'humps';

const axios = axiosApi.create({
  baseURL: API_ROOT,
  transformResponse: [
    ...axiosApi.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    data => decamelizeThatDontBreaksFile(data),
    ...axiosApi.defaults.transformRequest
  ]
});

const decamelizeThatDontBreaksFile = object => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map(item => decamelizeThatDontBreaksFile(item));
    }
    if (object instanceof FormData) {
      let formData = new FormData();
      for (const [key, value] of object.entries()) {
        formData.append(humps.decamelize(key), value);
      }
      return formData;
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [humps.decamelize(next)]: decamelizeThatDontBreaksFile(object[next])
        }),
        {}
      );
    }
  }
  return object;
};

const errorResponse = {
  response: {
    data: {
      message:
        'There was an issue connecting to our backend. Please drop an email to support@printrove.com',
      errors: {
        serverError: true
      }
    }
  }
};

axios.interceptors.response.use(
  response => response,
  error => {
    error = !error.response
      ? errorResponse
      : error.response.status === 500 ||
        error.response.status === 429 ||
        error.response.status === 404
      ? error && error.response.data.message !== ''
        ? error
        : errorResponse
      : error;
    return Promise.reject(error);
  }
);

export default axios;
