import React from 'react';
import { Button, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { PlusCircle } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { Refresh } from '@material-ui/icons';
import { getCredits } from 'src/actions/creditActions';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

function Credits() {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const credit = useSelector(state => state.credit);

  const dispatch = useDispatch();

  const refreshCredits = () => {
    dispatch(getCredits());
  };

  const openCreditsWindow = () => {
    var url = '/credits/add';
    window.open(url, '_blank', 'toolbar=yes,top=50,left=300,width=1000,height=800');
  };

  return (
    <>
      <Tooltip title="Add Credits">
        <Button
          onClick={openCreditsWindow}
          color="secondary"
          className={classes.button}
          startIcon={<PlusCircle size={20} />}
        >
          <Typography variant="h6">{`₹${
            credit ? credit.toFixed(2) : account.merchant.credits.toFixed(2)
          }`}</Typography>
        </Button>
      </Tooltip>
      <Tooltip title="Refresh credits">
        <IconButton color="secondary" size="small" onClick={refreshCredits}>
          <Refresh size={20} />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Credits;
