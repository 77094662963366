import axios from 'src/utils/axios';

export const GET_CREDITS = '@credit/get';
export const UPDATE_CREDITS = '@credit/update';

export function getCredits() {
  const request = axios.get('/user');
  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_CREDITS,
        payload: response.data
      })
    );
  };
}

export function updateCredits(credits) {
  return dispatch =>
    dispatch({
      type: UPDATE_CREDITS,
      payload: {
        credits
      }
    });
}
