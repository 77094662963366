export default {
  h1: {
    fontSize: 35,
    letterSpacing: 0,
    fontFamily: "'Lato', sans-serif",
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  h2: {
    fontSize: 29,
    letterSpacing: 0,
    fontFamily: "'Lato', sans-serif",
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  h3: {
    fontSize: 24,
    letterSpacing: 0,
    fontFamily: "'Lato', sans-serif",
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
    fontFamily: "'Open Sans', sans-serif"
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
    fontFamily: "'Open Sans', sans-serif"
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
    fontFamily: "'Open Sans', sans-serif"
  },
  overline: {
    fontWeight: 500,
    fontFamily: "'Lato', sans-serif"
  },
  body1: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400
  },
  body2: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400
  },
  subtitle1: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400
  }
};
