/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  UPDATE_CREDITS,
  REFRESH_ACCOUNT
} from 'src/actions/accountActions';

const initialState = {
  user: null,
  merchant: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, draft => {
        // Ensure we clear current session
        draft.user = null;
        draft.merchant = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user, merchant } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.merchant = merchant;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, draft => {
        draft.user = null;
        draft.merchant = null;
      });
    }

    case SILENT_LOGIN: {
      const { user, merchant } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.merchant = merchant;
      });
    }

    case UPDATE_PROFILE: {
      const { user, merchant } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.merchant = merchant;
      });
    }

    case REFRESH_ACCOUNT: {
      const { user, merchant } = action.payload;

      return produce(state, draft => {
        draft.user = user;
        draft.merchant = merchant;
      });
    }

    case UPDATE_CREDITS: {
      const { credits } = action.payload;

      return produce(state, draft => {
        draft.merchant.credits = credits;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
