import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        let response;
        try {
          response = await authService.loginInWithToken();
        } catch (error) {
          enqueueSnackbar(
            'There was an issue authenticating this request. Please login again.',
            {
              variant: 'error'
            }
          );
          setLoading(false);
          history.push('/login');
          return;
        }

        await dispatch(setUserData(response.user, response.merchant));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
