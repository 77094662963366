/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box, Divider, Drawer, Hidden, List, makeStyles, Fade, Typography } from '@material-ui/core'
import LogoWhite from 'src/components/LogoWhite'
import NavItem from './NavItem'
import { THEMES, navConfig } from 'src/constants'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import SubmitButton from 'src/components/SubmitButton'
import Label from 'src/components/Label'
import axios from 'src/utils/axios'
import { useSnackbar } from 'notistack'
import { refreshAccount } from 'src/actions/accountActions'
import amplitude from 'amplitude-js'

const useStyles = makeStyles(theme => ({
    root: {
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: 'none',
                  backgroundColor: '#202020'
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default
              }
            : {})
    },
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 42,
        top: 0,
        height: '100%',
        zIndex: theme.zIndex.drawer + 100,
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard
        }),
        '&:hover': {
            width: 256,
            // transform: 'translateX(0)',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.70), 0 24px 36px -8px rgba(0,0,0,0.50)'
        }
    },
    menuIcon: {
        zIndex: 1400,
        position: 'absolute',
        top: '50px',
        left: '10px',
        borderRadius: '10px',
        backgroundColor: '#334A5B',
        width: '30px',
        height: '30px',
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard
        })
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    logo: {
        width: 140,
        maxWidth: '100%'
    }
}))

function NavBar({ openMobile, onMobileClose }) {
    const classes = useStyles()
    const location = useLocation()
    const { user, merchant } = useSelector(state => state.account)
    let permissionsArray
    if (user && user.permissions) {
        permissionsArray = user.permissions.map(permission => permission.accessTo)
    }

    const [hover, setHover] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose()
        }
        // eslint-disable-next-line
    }, [location.pathname])

    const upgradePlan = () => {
        axios
            .get('user')
            .then(r => {
                const { merchant } = r.data
                if (merchant.isBusinessAccount) {
                    enqueueSnackbar('It looks like you have already upgraded to the business plan. Please reload the page.', {
                        variant: 'error'
                    })
                    dispatch(refreshAccount())
                    return
                } else {
                    amplitude.getInstance().logEvent('business plan upgrade started')
                    var url = '/credits/add?type=upgrade'
                    window.open(url, '_blank', 'toolbar=yes,top=50,left=300,width=800,height=800')
                }
            })
            .catch(e => {
                if (e.response.data.errors) {
                    const errors = e.response.data.errors
                    for (const field in errors) {
                        if (errors.hasOwnProperty(field)) {
                            const errorMessageArray = errors[field]
                            enqueueSnackbar(errorMessageArray[0], {
                                key: field,
                                variant: 'error',
                                anchorOrigin: ''
                            })
                        }
                    }
                } else {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    })
                }
            })
    }

    function renderNavItems({ items, ...rest }) {
        return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}</List>
    }

    function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
        const key = item.title + depth
        let showMenuItem = true
        if (item.permission && !user.isPrimary) {
            showMenuItem = false
            const titlePermission = item.permission.toLowerCase().replace(/ /g, '_')
            if (permissionsArray && permissionsArray.includes(titlePermission)) {
                showMenuItem = true
            }
        }
        if (showMenuItem) {
            if (item.items) {
                const open = matchPath(pathname, {
                    path: item.href,
                    exact: false
                })

                acc.push(
                    <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
                        {renderNavItems({
                            depth: depth + 1,
                            pathname,
                            items: item.items
                        })}
                    </NavItem>
                )
            } else {
                acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />)
            }
        }

        return acc
    }

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            className={clsx(classes.root)}
            onMouseEnter={() => {
                setHover(true)
            }}
            onMouseLeave={() => {
                setHover(false)
            }}
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box minHeight={72} p={2} display="flex" justifyContent="left">
                    {hover && (
                        <Fade in={hover} timeout={1000}>
                            <RouterLink to="/">
                                <LogoWhite className={classes.logo} />
                            </RouterLink>
                        </Fade>
                    )}
                </Box>
                <Box>
                    {navConfig.map(config => (
                        <List key={config.subheader}>
                            {renderNavItems({
                                items: config.items,
                                pathname: location.pathname
                            })}
                        </List>
                    ))}
                </Box>
                <Divider />
                {hover && (
                    <Fade in={hover} timeout={1000}>
                        <Box p={2}>
                            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
                                <Typography variant="h6" color="textPrimary">
                                    Current plan
                                </Typography>
                                {!merchant.isBusinessAccount ? (
                                    <>
                                        <Box mt={0.5}>
                                            <Label color="primary">Free</Label>
                                        </Box>
                                        <Box mt={2}>
                                            <SubmitButton color="primary" onClick={upgradePlan}>
                                                Upgrade
                                            </SubmitButton>
                                        </Box>
                                    </>
                                ) : (
                                    <Box mt={0.5}>
                                        <Label color="success">Business</Label>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Fade>
                )}
            </PerfectScrollbar>
        </Box>
    )

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
                    {content}
                </Drawer>
            </Hidden>

            <Hidden mdDown>
                {/* <DoubleArrowIcon
          fontSize="large"
          className={classes.menuIcon}
          style={{
            transform: hover
              ? 'translateX(230px) rotate(180deg)'
              : 'translateX(0px)'
          }}
          color="primary"
        /> */}
                <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}

export default NavBar
