import jwtDecode from 'jwt-decode'
import axios from 'src/utils/axios'

class AuthService {
    setAxiosInterceptors = ({ onLogout }) => {
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    this.setSession(null)

                    if (onLogout) {
                        onLogout()
                    }
                }

                return Promise.reject(error)
            }
        )
    }

    handleAuthentication() {
        const accessToken = this.getAccessToken()

        if (!accessToken) {
            return
        }

        if (this.isValidToken(accessToken)) {
            this.setSession(accessToken)
        } else {
            this.setSession(null)
        }
    }

    loginWithEmailAndPassword = (email, password, params) =>
        new Promise((resolve, reject) => {
            axios
                .post('/login', { email, password, ...params })
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.accessToken)
                        resolve(response.data)
                    } else {
                        reject(response.data.error)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })

    register = values =>
        new Promise((resolve, reject) => {
            axios
                .post('/register', values)
                .then(response => {
                    if (response.data.user) {
                        this.setSession(response.data.accessToken)
                        resolve(response.data)
                    } else {
                        reject(response.data.error)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })

    loginInWithToken = () =>
        new Promise((resolve, reject) => {
            axios
                .get('/user')
                .then(response => {
                    if (response.data.user) {
                        resolve(response.data)
                    } else {
                        reject(response.data.error)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })

    logout = () => {
        this.setSession(null)
        localStorage.removeItem('order')
    }

    setSession = accessToken => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken)
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        } else {
            localStorage.removeItem('accessToken')
            delete axios.defaults.headers.common.Authorization
        }
    }

    getAccessToken = () => localStorage.getItem('accessToken')

    isValidToken = accessToken => {
        if (!accessToken) {
            return false
        }

        const decoded = jwtDecode(accessToken)
        const currentTime = Date.now() / 1000

        return decoded.exp > currentTime
    }

    isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService()

export default authService
