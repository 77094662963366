import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import creditReducer from './creditReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  credit: creditReducer
});

export default rootReducer;
