/* eslint-disable no-param-reassign */
import { GET_CREDITS, UPDATE_CREDITS } from 'src/actions/creditActions';

const initialState = null;

const creditReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREDITS: {
      const { merchant } = action.payload;
      return merchant.credits;
    }
    case UPDATE_CREDITS: {
      const { credits } = action.payload;
      return credits;
    }

    default: {
      return state;
    }
  }
};

export default creditReducer;
